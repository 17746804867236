export const PaymentMethod = {
    Papara: 1,
    BankTransfer: 2,
    Cryptocurrency: 3,
    PaparaBank: 4,
    Pep: 5,
    PepBank: 6,
    CreditCard: 7,
    Parazula: 8,
    Popypara: 9,
    AutoPapara: 10,
    PLCWallet: 11,
  };
  
  export const PaymentMethodNames = {
    [PaymentMethod.Papara]: "Papara",
    [PaymentMethod.BankTransfer]: "Bank Transfer",
    [PaymentMethod.Cryptocurrency]: "Cryptocurrency",
    [PaymentMethod.PaparaBank]: "Papara Bank",
    [PaymentMethod.Pep]: "Pep",
    [PaymentMethod.PepBank]: "Pep Bank",
    [PaymentMethod.CreditCard]: "Credit Card",
    [PaymentMethod.Parazula]: "Parazula",
    [PaymentMethod.Popypara]: "Popypara",
    [PaymentMethod.AutoPapara]: "Auto Papara",
    [PaymentMethod.PLCWallet]: "PLC Wallet",
  };
  