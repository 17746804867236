import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./config/AuthContext";
import LoginPage from "./screens/login/Login";
import RegisterPage from "./screens/register/Register";
import Navbar from "./screens/navbar/Navbar";
import Sidebar from "./screens/sidebar/Sidebar";
import PrivateRoute from "./PrivateRoute";
import { getDashboard } from "./config/Config";

import Home from "./screens/sidebarcomponents/home/Home";
import Deposit from "./screens/sidebarcomponents/deposit/Deposit";
import Sendmoney from "./screens/sidebarcomponents/sendmoney/Sendmoney";
import Withdraw from "./screens/sidebarcomponents/withdraw/Withdraw";
import MybankAcc from "./screens/sidebarcomponents/mybankacc/MybankAcc";
import Operation from "./screens/sidebarcomponents/operation/Operation";
import Account from "./screens/sidebarcomponents/account/Account";

function App() {
  const [currentBalance, setCurrentBalance] = useState("");

  const updateBalance = async () => {
    const result = await getDashboard();
    if (
      result &&
      result.success &&
      Array.isArray(result.data) &&
      result.data.length > 0
    ) {
      const { balanceCurrent, currency } = result.data[0];
      setCurrentBalance(
        `${balanceCurrent.toLocaleString("tr-TR")} ${currency}`
      );
    } else {
      console.error("Error fetching dashboard data:", result.error);
    }
  };

  return (
    <AuthProvider>
      <div
        style={{ backgroundColor: "#F4F4F7", display: "flex", height: "100vh" }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/dashboard/*"
              element={
                <PrivateRoute>
                  <Dashboard
                    updateBalance={updateBalance}
                    currentBalance={currentBalance}
                  />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
}

const Dashboard = ({ updateBalance, currentBalance }) => {
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const [walletAddress, setWalletAddress] = useState("");

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible);
  };

  return (
    <>
      <Sidebar
        isBalanceVisible={isBalanceVisible}
        toggleBalanceVisibility={toggleBalanceVisibility}
        setWalletAddress={setWalletAddress}
        walletAddress={walletAddress}
        currentBalance={currentBalance}
        updateBalance={updateBalance}
      />
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Navbar />
        <div style={{ flex: 1, overflowY: "auto" }}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  walletAddress={walletAddress}
                  isBalanceVisible={isBalanceVisible}
                  toggleBalanceVisibility={toggleBalanceVisibility}
                />
              }
            />
            <Route path="/account" element={<Account />} />
            <Route
              path="/deposit"
              element={<Deposit updateBalance={updateBalance} />}
            />
            <Route
              path="/send-money"
              element={<Sendmoney updateBalance={updateBalance} />}
            />
            <Route
              path="/withdraw-money"
              element={<Withdraw updateBalance={updateBalance} />}
            />
            <Route path="/my-bank-account" element={<MybankAcc />} />
            <Route path="/operations" element={<Operation />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default App;
