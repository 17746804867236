import React, { useState, useEffect } from "react";
import "./Deposit.scss";
import { getPaymentMethods, createDeposit } from "../../../config/Config";
import Lottie from "react-lottie";
import checkmarkAnimation from "../../../animations/checkmark.json";
import cancelAnimation from "../../../animations/cancel.json";
import { BsSendCheckFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import { FaShareSquare, FaCheckCircle } from "react-icons/fa";

const cleanAmount = (amount) => {
  return parseFloat(amount.replace(/,/g, ""));
};

const Deposit = ({ updateBalance }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("USDT");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [animationType, setAnimationType] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    setLoading(true);
    const result = await getPaymentMethods();
    if (result.success) {
      const filteredMethods = result.data.filter(
        (method) => method.acceptDeposit
      );
      setPaymentMethods(filteredMethods);

      if (
        filteredMethods.length > 0 &&
        filteredMethods[0].supportedCurrencies.length > 0
      ) {
        setCurrency(filteredMethods[0].supportedCurrencies[0].currency);
      }
    } else {
      setErrorMessage(result.error);
    }
    setLoading(false);
  };

  const resetForm = () => {
    setAmount("");
    setCurrency("");
    setApiData(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setShowModal(false);

    const selectedMethod = paymentMethods.find(
      (method) => method.paymentMethod === activeTab
    );
    if (!selectedMethod) {
      setErrorMessage("No selected method found");
      return;
    }

    const cleanedAmount = cleanAmount(amount);
    if (isNaN(cleanedAmount) || cleanedAmount <= 0) {
      setErrorMessage("Please enter a value greater than zero.");
      return;
    }

    const result = await createDeposit(
      selectedMethod.paymentMethod,
      cleanedAmount,
      currency
    );

    if (result.success) {
      setSuccessMessage("Transaction created");
      setErrorMessage(null);
      setAnimationType("checkmark");
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
        setApiData(result.data); // API'den gelen veriyi sakla
        if (result.data.paymentPageUrl) {
          window.open(result.data.paymentPageUrl, "_blank"); // Yeni sayfada yönlendir
        }
        updateBalance();
        fetchPaymentMethods();
      }, 3000);
      resetForm();
    } else {
      const errorMessage =
        typeof result.error === "object"
          ? Object.values(result.error).join(", ")
          : result.error;
      setErrorMessage(errorMessage);
      setAnimationType("cancel");
      setShowAnimation(true);
      setTimeout(() => setShowAnimation(false), 3000);
      resetForm();
      fetchPaymentMethods();
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setAnimationType("cancel");
    setShowAnimation(true);
    setTimeout(() => setShowAnimation(false), 3000);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setAmount(value);
  };

  const handleTabChange = (paymentMethod, supportedCurrencies) => {
    setActiveTab(paymentMethod);
    setCurrency(supportedCurrencies[0].currency);
    setErrorMessage(null);
    setAmount("");
  };

  const renderContent = () => {
    const selectedMethod = paymentMethods.find(
      (method) => method.paymentMethod === activeTab
    );
    if (!selectedMethod) {
      return null;
    }

    const defaultOptionsCheckmark = {
      loop: false,
      autoplay: true,
      animationData: checkmarkAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const defaultOptionsCancel = {
      loop: false,
      autoplay: true,
      animationData: cancelAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="contentitems-deposit">
        <h2>Deposit with {selectedMethod.paymentMethodName}</h2>
        <form onSubmit={handleSubmit}>
          <label>Amount</label>
          <div className="amount">
            <input
              type="text"
              name="amount"
              className="amount-input"
              value={amount}
              onChange={handleAmountChange}
              pattern="\d*"
              inputMode="numeric"
            />
            <select
              name="currency"
              className="currency-select"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              {selectedMethod.supportedCurrencies.map((currency, index) => (
                <option key={index} value={currency.currency}>
                  {currency.currency}
                </option>
              ))}
            </select>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="button-container">
            <button className="button" type="submit">
              Continue
            </button>
          </div>
        </form>

        {successMessage && apiData && apiData.paymentPageUrl && (
          <div className="success-message">
            <span className="success-text"><FaCheckCircle />{successMessage}</span>
            <a
              href={apiData.paymentPageUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaShareSquare />
                Open Payment Page
            </a>
          </div>
        )}

        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h4>Confirmation</h4>
              <p>Are you sure you want to proceed with this transaction?</p>
              <div className="modal-buttons">
                <button onClick={handleConfirm} className="button-send">
                  <BsSendCheckFill size={19} />
                </button>
                <button onClick={handleCancel} className="button-cancel">
                  <MdCancel size={19} />
                </button>
              </div>
            </div>
          </div>
        )}

        {showAnimation && (
          <div className="animation-overlay">
            {animationType === "checkmark" ? (
              <Lottie
                options={defaultOptionsCheckmark}
                height={200}
                width={200}
              />
            ) : (
              <Lottie options={defaultOptionsCancel} height={200} width={200} />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="deposit-container">
      <div className="deposit-area">
        <div className="send-header">
          <span className="send-header"> Deposit</span>
        </div>

        <div className="option-tabs-area">
          <div className="tabs">
            {paymentMethods.map((method, index) => (
              <button
                key={method.paymentMethod}
                className={`tab-button ${
                  activeTab === method.paymentMethod ? "active" : ""
                }`}
                style={{
                  fontSize: "1em",
                  justifyContent: "center",
                  backgroundColor:
                    index % 3 === 0
                      ? "#AFC8AD"
                      : index % 3 === 1
                      ? "#C7C8CC"
                      : "#ADC4CE",
                }}
                onClick={() =>
                  handleTabChange(
                    method.paymentMethod,
                    method.supportedCurrencies
                  )
                }
              >
                <img 
                  src={method.logoUrl} 
                  alt={`${method.paymentMethodName} logo`} 
                  style={{ width: "80px", height: "80px", marginTop: "10px" }}
                />
                <span className="deposit-text">
                  Click for Deposit with{" "}
                  {method.paymentMethodName.toUpperCase()}
                </span>
              </button>
            ))}
          </div>

          <div className="content-deposit">{activeTab && renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
