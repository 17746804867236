import React, { useState, useEffect } from "react";
import "./Withdraw.scss";
import Lottie from "react-lottie";
import {
  getPaymentMethods,
  createWithDrawal,
  fetchSwiftCodes,
} from "../../../config/Config";
import checkmarkAnimation from "../../../animations/checkmark.json";
import cancelAnimation from "../../../animations/cancel.json";
import { BsSendCheckFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";

const Withdraw = ({ updateBalance }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [swiftCodes, setSwiftCodes] = useState([]);
  const [selectedSwiftCode, setSelectedSwiftCode] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [animationType, setAnimationType] = useState(null);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    setLoading(true);
    const result = await getPaymentMethods();
    if (result.success) {
      const filteredMethods = result.data.filter(
        (method) => method.acceptDeposit
      );
      setPaymentMethods(filteredMethods);

      if (
        filteredMethods.length > 0 &&
        filteredMethods[0].supportedCurrencies.length > 0
      ) {
        setCurrency(filteredMethods[0].supportedCurrencies[0].currency);
      }
    } else {
      setErrorMessage(result.error);
    }
    setLoading(false);
  };

  const resetForm = () => {
    setAmount("");
    setCurrency("");
    setAddress("");
    setSelectedSwiftCode("");
  };

  const cleanAmount = (amount) => {
    return parseFloat(amount.replace(/,/g, ""));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setShowConfirmationModal(true);
  };

  const handleSend = async () => {
    setShowConfirmationModal(false);
  
    const selectedMethod = paymentMethods.find(
      (method) => method.paymentMethod === activeTab
    );
    if (!selectedMethod) {
      setErrorMessage("No selected method found");
      console.error("No selected method found");
      return;
    }
  
    const cleanedAmount = cleanAmount(amount);
    if (isNaN(cleanedAmount) || cleanedAmount <= 0) {
      setErrorMessage("Please enter a value greater than zero.");
      return;
    }
  
    const withdrawalRequest = {
      paymentMethod: selectedMethod.paymentMethod,
      amount: cleanedAmount,
      currency: currency,
      swiftCode: selectedSwiftCode,
      address: address,
    };
  
    console.log("Withdrawal Request:", withdrawalRequest);
    const result = await createWithDrawal(withdrawalRequest);
  
    if (result.success) {
      setSuccessMessage("Withdrawal successful!");
      setErrorMessage(null);
      setAnimationType("checkmark");
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
        resetForm();
        updateBalance();
        fetchPaymentMethods();
      }, 3000);
    } else {
      let errorMessage = result.error;
      if (typeof errorMessage === "string") {
        errorMessage = errorMessage.split(",")[0]; // İlk virgülden sonrasını kes
      } else if (typeof errorMessage === "object" && errorMessage.title) {
        errorMessage = errorMessage.title; // Eğer hata bir nesne ise ve "title" varsa sadece "title"ı al
      } else {
        errorMessage = "An unknown error occurred.";
      }
  
      setErrorMessage(errorMessage);
      setAnimationType("cancel");
      setShowAnimation(true);
      setTimeout(() => setShowAnimation(false), 3000);
      resetForm();
      fetchPaymentMethods();
    }
  };
  

  const handleTabChange = async (paymentMethod, supportedCurrencies) => {
    setActiveTab(paymentMethod);
    setCurrency(supportedCurrencies[0].currency);
    setErrorMessage(null);

    resetForm();

    const result = await fetchSwiftCodes(paymentMethod);
    if (result.success) {
      setSwiftCodes(result.swiftCodes);
      if (result.swiftCodes.length === 1) {
        setSelectedSwiftCode(result.swiftCodes[0].swiftCode);
      }
    } else {
      setErrorMessage(result.error);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handleSwiftCodeSelect = (swiftCode) => {
    setSelectedSwiftCode(swiftCode);
  };

  const renderContent = () => {
    const selectedMethod = paymentMethods.find(
      (method) => method.paymentMethod === activeTab
    );
    if (!selectedMethod) {
      return null;
    }

    return (
      <div className="contentitems">
        <h2>Withdraw Money with {selectedMethod.paymentMethodName}</h2>
        <form onSubmit={handleSubmit}>
          {swiftCodes.length > 1 && (
            <div className="swiftcode-cards-container">
              {swiftCodes.map((code, index) => (
                <div
                  key={index}
                  className={`swiftcode-card ${
                    selectedSwiftCode === code.swiftCode ? "selected" : ""
                  }`}
                  onClick={() => handleSwiftCodeSelect(code.swiftCode)}
                >
                  {code.name}
                </div>
              ))}
            </div>
          )}

          <label>Amount</label>
          <div className="amount">
            <input
              type="text"
              name="amount"
              className="amount-input"
              value={amount}
              placeholder="Enter amount"
              onChange={handleAmountChange}
            />
            <select
              name="currency"
              className="currency-select"
              value={currency}
              onChange={handleCurrencyChange}
            >
              {selectedMethod.supportedCurrencies.map((currency, index) => (
                <option key={index} value={currency.currency}>
                  {currency.currency}
                </option>
              ))}
            </select>
          </div>
          <label>Address</label>

          <div className="amount">
            <input
              type="text"
              value={address}
              className="amount-input"
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter address"
              required
            />
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}

          <div className="button-container">
            <button className="button" type="submit">
              Continue
            </button>
          </div>
        </form>
      </div>
    );
  };

  const defaultOptionsCheckmark = {
    loop: false,
    autoplay: true,
    animationData: checkmarkAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptionsCancel = {
    loop: false,
    autoplay: true,
    animationData: cancelAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="withdraw-container">
      <div className="deposit-area">
        <div className="send-header">
          <span className="send-text"> Withdrawal</span>
        </div>

        <div className="option-tabs-area">
          <div className="tabs">
            {paymentMethods.map((method, index) => (
              <button
                key={method.paymentMethod}
                className={`tab-button ${
                  activeTab === method.paymentMethod ? "active" : ""
                }`}
                style={{
                  fontSize: "1em",
                  backgroundColor:
                    index % 3 === 0
                      ? "#AFC8AD"
                      : index % 3 === 1
                      ? "#C7C8CC"
                      : "#ADC4CE",
                }}
                onClick={() =>
                  handleTabChange(
                    method.paymentMethod,
                    method.supportedCurrencies
                  )
                }
              >
                <img
                  src={method.logoUrl}
                  alt={`${method.paymentMethodName} logo`}
                  style={{ width: "80px", height: "80px", marginTop: "10px" }}
                />
                <span className="deposit-text">
                  Click for Withdraw with{" "}
                  {method.paymentMethodName.toUpperCase()}
                </span>
              </button>
            ))}
          </div>

          <div className="content-deposit">{activeTab && renderContent()}</div>
        </div>

        {showConfirmationModal && (
          <div className="modal">
            <div className="modal-content">
              <p>
                You are about to withdraw{" "}
                <strong>
                  {amount} {currency}
                </strong>{" "}
                using{" "}
                <strong>
                  {
                    paymentMethods.find(
                      (method) => method.paymentMethod === activeTab
                    )?.paymentMethodName
                  }
                </strong>
                .
              </p>
              <div className="modal-buttons">
                <button onClick={handleSend} className="button-send">
                  <BsSendCheckFill size={19} />
                </button>
                <button
                  onClick={() => setShowConfirmationModal(false)}
                  className="button-cancel"
                >
                  <MdCancel size={19} />
                </button>
              </div>
            </div>
          </div>
        )}

        {showAnimation && (
          <div className="animation-overlay">
            {animationType === "checkmark" ? (
              <Lottie
                options={defaultOptionsCheckmark}
                height={200}
                width={200}
              />
            ) : (
              <Lottie options={defaultOptionsCancel} height={200} width={200} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Withdraw;
