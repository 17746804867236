import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./config/AuthContext";

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Sayfa yenilendiğinde kullanıcı bilgilerini kontrol ederken yükleme göstergesi
  }

  return isLoggedIn ? children : <Navigate to="/" />;
};

export default PrivateRoute;
