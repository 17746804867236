import React, { useEffect, useState } from "react";
import "./Account.scss";
import {
  getAccInfo,
  getAccWallet,
  getTransactions,
} from "../../../config/Config";
import { FaRegCopy, FaSearch, FaArrowUp, FaArrowDown } from "react-icons/fa";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import { PaymentMethodNames } from "../../../enums/PaymentMethod";
import { TransactionStatusNames } from "../../../enums/TransactionStatus";
import { TransactionTypeNames } from "../../../enums/TransactionType";
import WeeklyTransactionChart from "./Table";
import { IoAdd } from "react-icons/io5";
import { FaSpinner, FaTimes, FaCheck } from "react-icons/fa";

const BalanceCard = ({ balance, currency }) => (
  <div className="balance-card">
    <div className="balance-card-header">
      <span>Total Balance</span>
    </div>
    <div className="balance-card-main">
      <span className="total-balance">
        <span className="currency">{currency} </span>
        {balance}
      </span>
    </div>
    <div className="balance-card-actions">
      <button className="btn-deposit">Deposit</button>
      <button className="btn-withdraw">Withdraw</button>
    </div>
  </div>
);

const TransactionHistory = ({
  transactions,
  getTransactionStatusName,
  getPaymentMethodName,
  getTransactionTypeName,
  formatDate,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTransactions = transactions.filter(
    (transaction) =>
      transaction.id.toString().includes(searchQuery) ||
      getTransactionStatusName(transaction.transactionStatus)
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      getPaymentMethodName(transaction.paymentMethod)
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      transaction.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction.amount.toString().includes(searchQuery) ||
      formatDate(transaction.transactionDate).includes(searchQuery)
  );

  // Pagination
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const renderStatusIcon = (status) => {
    switch (status) {
      case 2:
        return <FaSpinner style={{ color: "orange" }} />;
      case 3:
        return <FaCheck style={{ color: "green" }} />;
      case 4:
        return <FaTimes style={{ color: "red" }} />;
      default:
        return getTransactionStatusName(status);
    }
  };

  return (
    <div className="transaction-history-container">
      <div className="transaction-details">
        <div className="transaction-header">
          <h3>Transaction History</h3>
          <div className="search-container">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ textAlign: "center"}}>Status</th>
              <th style={{ textAlign: "center"}}>From</th>
              <th style={{ textAlign: "center"}}> To</th>
              <th style={{ textAlign: "right" }}>Amount</th>{" "}
              {/* Align header to the right */}
              <th style={{ textAlign: "right" }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.id}</td>
                <td style={{ textAlign: "center" }}>
                  {renderStatusIcon(transaction.transactionStatus)}
                </td>{" "}
                {/* Center-align status */}
                <td style={{ textAlign: "center"}}>{transaction.from || "Null"}</td>
                <td style={{ textAlign: "center"}}>{transaction.to || "Null"}</td>
                <td
                  style={{
                    color: transaction.transactionType === 1 ? "green" : "red",
                    textAlign: "right", // Align amount to the right
                  }}
                >
                  {transaction.transactionType === 1 ? "+" : "-"}
                  {transaction.amount} {transaction.currency}
                </td>
                <td
                  style={{
                    textAlign: "right", // Align amount to the right
                  }}
                >
                  {formatDate(transaction.transactionDate)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button
            className="pagination-arrow"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            &lt;
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`pagination-button ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="pagination-arrow"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

const Account = () => {
  const [accounts, setAccounts] = useState([]);
  const [walletInfo, setWalletInfo] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [walletInfoError, setWalletInfoError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAccountInfo, setShowAccountInfo] = useState(true); // State for toggling visibility
  const itemsPerPage = 10; // Number of transactions per page

  useEffect(() => {
    const fetchAccountInfo = async () => {
      const result = await getAccInfo();
      if (result.success) {
        setAccounts(result.data);
        if (result.data.length === 1) {
          fetchWalletInfo(result.data[0].id);
        }
      } else {
        console.error("Failed to fetch account info:", result.error);
      }
    };

    fetchAccountInfo();
  }, []);

  const fetchWalletInfo = async (id) => {
    setLoading(true);
    setWalletInfoError(false);
    setTransactions([]);
    const result = await getAccWallet(id);
    if (result.success) {
      setWalletInfo(result.data);
      setSelectedAccountId(id);
      fetchTransactions(id); // Fetch transactions when account is selected
    } else {
      console.error("Failed to fetch wallet info:", result.error);
      setWalletInfoError(true);
    }
    setLoading(false);
  };

  const fetchTransactions = async (accountId) => {
    const result = await getTransactions(accountId);
    if (result.success) {
      setTransactions(result.data);
    } else {
      console.error("Failed to fetch transactions:", result.error);
    }
  };

  const formatBalance = (balance) =>
    balance.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const formatAccountAddress = (address) => address.replace(/(.{5})/g, "$1 ");

  const formatDate = (dateString) =>
    new Date(dateString).toLocaleString("tr-TR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

  const getPaymentMethodName = (value) => PaymentMethodNames[value] || value;
  const getTransactionStatusName = (value) =>
    TransactionStatusNames[value] || value;
  const getTransactionTypeName = (value) =>
    TransactionTypeNames[value] || value;

  const totalPages = Math.ceil(transactions.length / itemsPerPage);

  return (
    <div className="account-container">
      <div
        className="account-card-area"
        onClick={() => setShowAccountInfo(!showAccountInfo)}
      >
        {walletInfo ? (
          <>
            <div className="card-account">
              <div className="card-section card-section-left"></div>
              <div className="card-section card-section-middle"></div>
              <div className="card-section card-section-right"></div>

              <div className="card-account-header">
                <div className="card-brand">PLC Wallet</div>
              </div>

              <div className="card-account-number">
                <span>{formatAccountAddress(walletInfo.accountAddress)}</span>
              </div>
              <div className="card-account-balance">
                <div>
                  <span className="currency">{walletInfo.currency} </span>
                  <span className="amount">
                    {formatBalance(walletInfo.balance)}
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <button className="add-button">
                <IoAdd />
              </button>
            </div>
          </>
        ) : (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>No wallet information available.</span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <button className="add-button">
                <IoAdd />
              </button>
            </div>
          </div>
        )}
      </div>

      {showAccountInfo && walletInfo && (
        <div className="account-info">
          <div className="summary-dashboard-container">
            <div className="account-summary">
              {walletInfo && (
                <BalanceCard
                  balance={formatBalance(walletInfo.balance)}
                  currency={walletInfo.currency}
                />
              )}
            </div>
            <div className="dashboard-container">
              <WeeklyTransactionChart transactions={transactions} />
            </div>
          </div>

          <TransactionHistory
            transactions={transactions}
            getTransactionStatusName={getTransactionStatusName}
            getPaymentMethodName={getPaymentMethodName}
            getTransactionTypeName={getTransactionTypeName}
            formatDate={formatDate}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </div>
  );
};

export default Account;
