import React from "react";
import "./MybankAcc.scss";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MybankAcc = () => {

    const navigate = useNavigate();

  return (
    <div className="mybankacc-container">
      <div className="banksa">
        <div className="bank-header">
          <span>Add Bank Account</span>
          <button>Contracted Banks</button>
        </div>
        <div className="form">
          <div className="contentitems">
            <form>
              <label> Name Surname</label>
              <input type="text" name="namesurname" />
              <label>Nickname</label>
              <input type="text" name="nickname" />
              <label>IBAN No</label>
              <input type="text" name="ibanno" />

              <div className="button-container">
                <button className="button" onClick={() => navigate("/my-bank-account")}>Create Account <FaCheck /></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MybankAcc;
