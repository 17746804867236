import React, { useState } from "react";
import "./Login.scss";
import leftimage from "../../assets/login.jpeg";
import { FaLock } from "react-icons/fa";
import { login as loginUser } from "../../config/Config";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../config/AuthContext";
import { IoMdLogIn } from "react-icons/io";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [userEmail, setUserEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    const result = await loginUser(email, password);
    if (result.success) {
      setUserEmail(email); 
      login({ username: email }, result.data.accessToken, result.data.refreshToken);
      navigate("/dashboard", { state: { email } }); // Pass email in state
    } else {
      setError(result.error);
    }
  };
  
  return (
    <div className="login-container">
     
      <div className="section">
        <div className="loginheader">
          <IoMdLogIn size={60} color="#5d9599"/>
          <span>Sign In</span>
        </div>
        {/* <div className="language-section">
          <h5>English / Türkçe</h5>
        </div>
        <h5>Sign in to your PLC wallet account.</h5>
        <div className="url-div">
          <span>
            <FaLock className="icon" />
            <span className="divider"></span>
            <span style={{ color: "#138034" }}>https:</span>
            wallet.paylayercore.com.tr
          </span>
        </div>
        <span className="urlnote">
          Your browser's address bar should look like the figure above.
        </span> */}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              placeholder="Email"
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              placeholder="Password"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className="button" type="submit">
            Sign In
          </button>
          {error && <div className="error-message">{error}</div>}
        </form>
        <div className="p">
          <p>Did you forget your password ?</p>
          <p>
            Don't have an account ? <a href="/register">Sign Up</a>
          </p>
          <p>How can we help you?</p>
        </div>
        <div className="footer">
          <div className="divider"></div>
          <div className="rezerved">
            © <a href="/#">Paylayercore </a> All Rights Reserved.
          </div>
          <div className="divider"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
