export const TransactionStatus = {
  New: 1,
  Pending: 2,
  Confirm: 3,
  Reject: 4,
};

export const TransactionStatusNames = {
  [TransactionStatus.New]: "New",
  [TransactionStatus.Pending]: "Pending",
  [TransactionStatus.Confirm]: "Confirm",
  [TransactionStatus.Reject]: "Reject",
};
