import axios from "axios";

const isDevelopment = process.env.NODE_ENV === "development";

const AUTH_URL = isDevelopment ? '/api/v1/auth' : 'https://testauth.paylayercore.com/api/v1/auth';
const BASE_URL = isDevelopment ? '/api/v1' : 'https://testpaywallet.paylayercore.com/api/v1';

const axiosAuthInstance = axios.create({
  baseURL: AUTH_URL, // setupProxy.js ile yönlendirilecek
  headers: {
    "Content-Type": "application/json",
  },
});

// Axios instance for other transactions
const axiosTransactionInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosAuthInstance.interceptors.response.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Set Authorization header for requests
axiosTransactionInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Authentication functions using axiosAuthInstance
export const login = async (email, password) => {
  try {
    const response = await axiosAuthInstance.post("/login", {
      email,
      password,
    });
    localStorage.setItem("accessToken", response.data.accessToken);

    // Log the config data
    console.log(response.config);

    // Extract and log the email from the config data
    if (response.config && response.config.data) {
      try {
        const configData = JSON.parse(response.config.data);
        console.log("Email:", configData.email);
      } catch (parseError) {
        console.error("Error parsing config data:", parseError);
      }
    } else {
      console.warn("No config data available in the response.");
    }

    return { success: true, data: response.data };
  } catch (error) {
    console.error("Login error:", error.response?.data || error.message);
    return { success: false, error: error.response?.data || error.message };
  }
};


export const register = async (
  firstName,
  lastName,
  email,
  password,
  confirmPassword
) => {
  let result;
  try {
    const response = await axiosAuthInstance.post("/register", {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    });
    result = { success: response.status === 204, data: response.data };
  } catch (error) {
    console.error(
      "API error:",
      error.response ? error.response.data : error.message
    );
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};

// Transaction functions using axiosTransactionInstance
export const getPaymentMethods = async () => {
  let result;
  try {
    const response = await axiosTransactionInstance.get(
      "/transaction/paymentmethod"
    );
    result = { success: true, data: response.data };
  } catch (error) {
    console.error(
      "API error:",
      error.response ? error.response.data : error.message
    );
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};

export const createDeposit = async (paymentMethod, amount, currency) => {
  let result;
  try {
    const response = await axiosTransactionInstance.post(
      "/Transaction/deposit",
      {
        paymentMethod,
        amount,
        currency,
      }
    );
    console.log("API Response:", response); // Log the entire response
    result = {
      success: response.data.success || response.status === 200,
      data: response.data,
    };
  } catch (error) {
    console.error(
      "API error:",
      error.response ? error.response.data : error.message
    );
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};


export const createSendMoney = async (
  address,
  amount,
  currency,
  explanation
) => {
  let result;
  try {
    const response = await axiosTransactionInstance.post(
      "/Transaction/sendp2p",
      {
        address,
        amount,
        currency,
        explanation,
      }
    );
    result = {
      success: response.data.success || response.status === 204,
      data: response.data,
    };
  } catch (error) {
    console.error(
      "API error:",
      error.response ? error.response.data : error.message
    );
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};

export const getTransactions = async (period, recordCount) => {
  let result;
  try {
    const response = await axiosTransactionInstance.get("/Transaction", {
      params: { period, recordCount },
    });
    result = { success: true, data: response.data };
  } catch (error) {
    console.error(
      "API error:",
      error.response ? error.response.data : error.message
    );
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};

export const getDashboard = async () => {
  try {
    const response = await axiosTransactionInstance.get("/Report/dashboard");
    console.log("Dashboard data received:", response.data);  // Verileri konsola loglayın
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Failed to fetch dashboard data:", error);
    return { success: false, error: error.response ? error.response.data : error.message };
  }
};


export const getAccInfo = async () => {
  let result;

  try {
    const response = await axiosTransactionInstance.get("/Account");
    result = { success: true, data: response.data };
  } catch (error) {
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};

export const getAccWallet = async (id) => {
  let result;

  try {
    const response = await axiosTransactionInstance.get(`/AccountWallet/${id}`);
    result = { success: true, data: response.data };
  } catch (error) {
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};


export const createWithDrawal = async (withdrawalRequest) => {
  let result;
  try {
    const response = await axiosTransactionInstance.post(
      "/Transaction/withdrawal",
      withdrawalRequest // withdrawalRequest doğrudan gönderiliyor
    );
    result = {
      success: response.data.success || response.status === 200,
      data: response.data,
    };
  } catch (error) {
    console.error(
      "API error:",
      error.response ? error.response.data : error.message
    );
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};

export const fetchSwiftCodes = async (paymentMethod) => {
  let result;
  try {
    const response = await axiosTransactionInstance.get(
      `/transaction/swiftcode/${paymentMethod}`
    );
    console.log("Swift Codes Response:", response.data); // Gelen swift kodları loglayın

    if (Array.isArray(response.data) && response.data.length > 0) {
      if (response.data.length === 1) {
        result = {
          success: true,
          swiftCodes: response.data,
          selectedSwiftCode: response.data[0].swiftCode, // Tek swift kodu varsa otomatik seç
        };
      } else {
        result = {
          success: true,
          swiftCodes: response.data,
          selectedSwiftCode: "", // Kullanıcının seçmesi için boş bırak
        };
      }
    } else {
      result = {
        success: false,
        swiftCodes: [],
        selectedSwiftCode: "",
        error: "No swift codes available",
      };
    }
  } catch (error) {
    console.error("Failed to fetch swift codes:", error);
    result = {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
  return result;
};



export { axiosAuthInstance, axiosTransactionInstance };
