import React, { useState, useEffect } from "react";
import {
  FaTurkishLiraSign,
  FaClock,
  FaCheck,
  FaUser,
  FaArrowUp,
  FaArrowDown,
  FaRegCopy,
  FaMoneyBill,
} from "react-icons/fa6";
import { GrMoney } from "react-icons/gr";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import "./Home.scss";
import { getDashboard, getTransactions } from "../../../config/Config";
import { TransactionTypeNames } from "../../../enums/TransactionType";
import { TransactionStatusNames } from "../../../enums/TransactionStatus";
import { FaSpinner, FaTimes } from "react-icons/fa";

import { useLocation } from "react-router-dom";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip);

const Home = ({ walletAddress, isBalanceVisible, toggleBalanceVisibility }) => {
  const [currentBalance, setCurrentBalance] = useState("");
  const [pendingBalance, setPendingBalance] = useState("");
  const [remainingLimit, setRemainingLimit] = useState("");
  const [showFullAddress, setShowFullAddress] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState([]);

  const location = useLocation();
  const { email } = location.state || {};

  const toggleFullAddress = () => {
    setShowFullAddress(!showFullAddress);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Copied to clipboard:", text);
        alert("Copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text:", err);
      }
    );
  };

  const months = [
    { name: "Jan", amount: 220 },
    { name: "Feb", amount: 5000 },
    { name: "Mar", amount: 100 },
    { name: "Apr", amount: 150 },
    { name: "May", amount: 300 },
    { name: "Jun", amount: 450 },
    { name: "Jul", amount: 600 },
    { name: "Aug", amount: 250 },
    { name: "Sep", amount: 350 },
    { name: "Oct", amount: 500 },
    { name: "Nov", amount: 700 },
    { name: "Dec", amount: 400 },
  ];

  const data = {
    labels: months.map((month) => month.name),
    datasets: [
      {
        label: "Usage (TRY)",
        data: months.map((month) => month.amount),
        backgroundColor: [
          "#FF6384",
          "#5d9599",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        borderRadius: 5, // Rounded corners for bars
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      title: {
        display: true,
        text: "Monthly Usage Summary",
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines
        },
        ticks: {
          color: "#999", // Color for x-axis labels
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Hide grid lines
        },
        ticks: {
          display: false, // Show y-axis labels
        },
      },
    },
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      const result = await getDashboard();
      if (
        result &&
        result.success &&
        Array.isArray(result.data) &&
        result.data.length > 0
      ) {
        const { balanceCurrent, balancePending, currency } = result.data[0];
        setCurrentBalance(
          `${balanceCurrent.toLocaleString("tr-TR")} ${currency}`
        );
        setPendingBalance(
          `${balancePending.toLocaleString("tr-TR")} ${currency}`
        );
        setRemainingLimit(`∞`);
      } else {
        console.error("Error fetching dashboard data:", result.error);
      }
    };

    const fetchRecentTransactions = async () => {
      const result = await getTransactions();
      if (result.success) {
        const recent = result.data
          .filter(
            (transaction) =>
              transaction.transactionType === 1 ||
              transaction.transactionType === 2
          )
          .sort(
            (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
          )
          .slice(0, 5);
        setRecentTransactions(recent);
      } else {
        console.error("Error fetching transactions:", result.error);
      }
    };

    fetchDashboardData();
    fetchRecentTransactions();
  }, []);

  const cards = [
    {
      key: 1,
      title: "Current Balance",
      amount: isBalanceVisible ? currentBalance : "********",
      icon: <GrMoney color="#57606c" size={30} />,
      color: "#B5C0D0",
      amountStyle: { fontSize: "1.9em" }, // Increase font size for Current Balance
    },
    {
      key: 4,
      title: "PLC Number",
      amountStyle: { fontSize: "1em" },
      amount: (
        <div style={{ display: "flex", alignItems: "center" }}>
          {walletAddress &&
            (showFullAddress
              ? walletAddress
              : `${walletAddress.substring(0, 15)}...`)}
          <FaRegCopy
            color="57606c"
            style={{ marginLeft: 10, cursor: "pointer" }}
            onClick={() => copyToClipboard(walletAddress)}
          />
        </div>
      ),
      icon: <FaUser color="57606c" size={30} />,
      color: "#b0cdcf",
      toggleFullAddress,
      titleColor: "#57606c", // Blue color for title
      amountColor: "#57606c",
    },
    {
      key: 2,
      title: "Pending Balance",
      amount: isBalanceVisible ? pendingBalance : "********",
      icon: <FaClock color="#57606c" size={30} />,
      color: "#CCD3CA",
      amountStyle: { fontSize: "1.5em" }, // Increase font size for Current Balance
    },
    {
      key: 3,
      title: "Remaining Upload Limit",
      amount: isBalanceVisible ? remainingLimit : "********",
      icon: <FaCheck color="#57606c" size={30} />,
      color: "#cfb0b0",
    },
  ];

  const formatDate = (dateString) =>
    new Date(dateString).toLocaleString("tr-TR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  const renderStatusIcon = (status) => {
    switch (status) {
      case 2:
        return <FaSpinner style={{ color: "orange" }} />;
      case 3:
        return <FaCheck style={{ color: "green" }} />;
      case 4:
        return <FaTimes style={{ color: "red" }} />;
      default:
        return getTransactionStatusName(status);
    }
  };
  const getTransactionTypeName = (value) =>
    TransactionTypeNames[value] || value;
  const getTransactionStatusName = (value) =>
    TransactionStatusNames[value] || value;
  return (
    <div className="home-container">
      <div style={{ paddingLeft: 20 }}>
        Welcome,{" "}
        <span style={{ fontWeight: 700, color: "#57606c" }}>{email}</span> !
      </div>
      <div className="card-container">
        {cards.map((item) => (
          <div
            key={item.key}
            className="card"
            style={{
              backgroundColor: item.color,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // Space between title and amount
              padding: "20px",
            }}
            onClick={item.toggleFullAddress ? item.toggleFullAddress : null}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center", // Vertically centers the items
                justifyContent: "flex-start", // Aligns content to the left
              }}
            >
              <span style={{ marginRight: 8 }}>{item.icon}</span>
              <span
                style={{
                  color: item.titleColor || "#57606c",
                  textAlign: "left", // Aligns text to the left
                }}
              >
                {item.title} 
              </span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start", // Aligns amount to the left
                marginTop: "20px", // Adjust spacing as needed
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  color: item.amountColor || "#57606c",
                  ...(item.amountStyle || {}),
                }}
              >
                {item.amount}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="user-info">
          <div className="wallet-usage-bar-chart">
            <Bar data={data} options={options} />
          </div>
        </div>
        <div className="recent-activities">
          <span>Recent Activities</span>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "0.6fr 2fr 2fr", // Match the column structure
              fontWeight: "600", // Make headers bold
              color: "#878787", // Set a consistent color for headers
              paddingLeft: 10 ,
              paddingRight:10,
              fontSize:13
            }}
          >
            <div style={{ textAlign: "left" }}></div>
            <div style={{ textAlign: "left" }}>Date</div>
            <div style={{ textAlign: "right" }}>Amount</div>
          </div>

          {/* Transaction List */}
          <ul className="userinfo-items">
            {recentTransactions.map((activity) => (
              <li key={activity.id}>
                <div style={{ padding: 10 }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "0.6fr 2fr 2fr", // Match the column structure
                      alignItems: "center",
                      fontSize:13

                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <span style={{ color: "#999", fontWeight: 500 }}>
                        {renderStatusIcon(activity.transactionStatus)}
                      </span>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <span
                        style={{
                          color: "#999",
                          fontWeight: 500,
                        }}
                      >
                        {formatDate(activity.transactionDate)}
                      </span>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <span style={{ color: "#999", fontWeight: 500 }}>
                        {activity.currency} {activity.amount} of &nbsp;
                      </span>
                      <span
                        style={{
                          color:
                            activity.transactionType === 2
                              ? "red"
                              : activity.transactionType === 1
                              ? "green"
                              : "#999",
                          fontWeight: 500,
                        }}
                      >
                        {getTransactionTypeName(activity.transactionType)}&nbsp;
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
