import React, { useState, useEffect } from "react";
import "./Operation.scss";
import { getTransactions } from "../../../config/Config";
import { PaymentMethodNames } from "../../../enums/PaymentMethod";
import { TransactionStatusNames } from "../../../enums/TransactionStatus";
import { TransactionTypeNames } from "../../../enums/TransactionType";

const Operation = () => {
  const [period, setPeriod] = useState("1week");
  const [recordCount, setRecordCount] = useState("5");
  const [transactions, setTransactions] = useState([]);
  const [filters, setFilters] = useState({ period: "1week", recordCount: "5" });

  const fetchTransactions = async (filterPeriod, filterRecordCount) => {
    const result = await getTransactions(filterPeriod, filterRecordCount);
    if (result.success) {
      setTransactions(result.data);
    } else {
      console.error(result.error);
    }
  };

  useEffect(() => {
    fetchTransactions(filters.period, filters.recordCount);
  }, [filters]);

  const handleFilterClick = () => {
    setFilters({ period, recordCount });
  };

  const handleClearClick = () => {
    setPeriod("1week");
    setRecordCount("5");
    setFilters({ period: "1week", recordCount: "5" });
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  const filterTransactions = () => {
    const now = new Date();
    const periods = {
      "1day": { days: 1 },
      "1week": { days: 7 },
      "2week": { days: 14 },
      "1month": { months: 1 },
      "3month": { months: 3 },
      "6month": { months: 6 },
      "1year": { years: 1 },
    };

    let filteredTransactions = transactions;

    if (periods[filters.period]) {
      const { days, months, years } = periods[filters.period];
      const filterDate = new Date(now);

      if (days) {
        filterDate.setDate(now.getDate() - days);
      }
      if (months) {
        filterDate.setMonth(now.getMonth() - months);
      }
      if (years) {
        filterDate.setFullYear(now.getFullYear() - years);
      }

      filteredTransactions = transactions.filter(
        (transaction) => new Date(transaction.transactionDate) >= filterDate
      );
    }

    return filteredTransactions.slice(0, parseInt(filters.recordCount));
  };

  const getPaymentMethodName = (value) => PaymentMethodNames[value] || value;

  const getTransactionStatusName = (value) => {
    switch (value) {
      case 2:
        return <span style={{ color: "orange", fontSize:'0.95em' }}>{TransactionStatusNames[value]}</span>;
      case 3:
        return <span style={{ color: "green", fontSize:'0.95em' }}>{TransactionStatusNames[value]}</span>;
      case 4:
        return <span style={{ color: "red", fontSize:'0.95em' }}>{TransactionStatusNames[value]}</span>;
      default:
        return TransactionStatusNames[value];
    }
  };

  const getTransactionTypeName = (value) => TransactionTypeNames[value] || value;

  const filteredTransactions = filterTransactions();

  return (
    <div className="sendmoney-container">
      <div className="sendmoney-area">
        <span>Operations</span>

        <div className="filter-container">
          <div className="selects">
            <label htmlFor="period">Period</label>
            <select
              id="period"
              name="period"
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
            >
              <option value="1day">Last 1 day</option>
              <option value="1week">Last 1 week</option>
              <option value="2week">Last 2 week</option>
              <option value="1month">Last 1 month </option>
              <option value="3month">Last 3 month</option>
              <option value="6month">Last 6 month</option>
              <option value="1year">Last 1 year</option>
            </select>

            <label htmlFor="recordCount">Record Count</label>
            <select
              id="recordCount"
              name="recordCount"
              value={recordCount}
              onChange={(e) => setRecordCount(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>

          <div className="button-group">
            <button className="filter-button" onClick={handleFilterClick}>
              Filter
            </button>
            <button className="clear-button" onClick={handleClearClick}>
              Clean
            </button>
          </div>
        </div>

        <div className="transaction-table">
          {filteredTransactions.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Operation ID</th>
                  <th style={{ textAlign: "right" }}>Amount</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th style={{ textAlign: "center" }}>Status</th>
                  <th style={{ textAlign: "center" }}>Payment Method</th>
                  <th style={{ textAlign: "right" }}>Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td>{transaction.id}</td>
                    <td style={{ textAlign: "right",color: transaction.transactionType === 1 ? "green" : "red" }}>{transaction.transactionType === 1 ? "+" : "-"}{transaction.amount}</td>
                    <td style={{ color: transaction.transactionType === 1 ? "green" : "red"}}>{transaction.currency}</td>
                    <td>{getTransactionTypeName(transaction.transactionType)}</td>
                    <td style={{ textAlign: "center" }}>
                      {getTransactionStatusName(transaction.transactionStatus)}
                    </td>
                    <td style={{ textAlign: "center" }}>{getPaymentMethodName(transaction.paymentMethod)}</td>
                    <td style={{ textAlign: "right" }}>{formatDate(transaction.transactionDate)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no-transactions">No transactions found for the selected period.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Operation;
