import React, { useState } from "react";
import { FaHome, FaCaretDown, FaFlag, FaUser, FaSignOutAlt } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineUser } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import "./Navbar.scss";
import { sidebaritems } from "../sidebar/Sidebar";
import { useAuth } from "../../config/AuthContext";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenLanguage, setDropdownOpenLanguage] = useState(false);
  const [dropdownOpenAccount, setDropdownOpenAccount] = useState(false);
  const { logout } = useAuth(); // useAuth'dan logout'u alıyoruz
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdownLanguage = () => {
    setDropdownOpenLanguage(!dropdownOpenLanguage);
  };

  const toggleDropdownAccount = () => {
    setDropdownOpenAccount(!dropdownOpenAccount);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };



  const filteredSidebarItems = sidebaritems.filter(item => item.name !== "Sign Out");

  return (
    <nav>
      <div className="menu">
        <div onClick={() => handleNavigation("/dashboard")} className="icon">
          <IoMdNotificationsOutline size={24}/> 
        </div>
        <div className="icon" onClick={toggleDropdown} >
           <AiOutlineUser size={24}/>
        </div>
        {/* <div className="dropdown" onClick={toggleDropdownLanguage}>
          <FaFlag /> English <FaCaretDown />
          {dropdownOpenLanguage && (
            <div className="dropdown-content">
              {languages.map((language) => (
                <div key={language.key} onClick={() => console.log(`Language changed to ${language.name}`)}>
                  {language.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="dropdown" onClick={toggleDropdownAccount}>
          <FaUser /> Account <FaCaretDown />
          {dropdownOpenAccount && (
            <div className="dropdown-content">
              {accountitems.map((item) => (
                <div key={item.key} onClick={item.action || (() => console.log(item.title))}>
                  {item.icon} {item.title}
                </div>
              ))}
            </div>
          )}
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
